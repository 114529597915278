<template>
    <div class="container">
        <div class="search" style="display:flex">
            <el-form
                ref="form"
                style="flex:1"
                :inline="true"
                :model="search"
                class="demo-form-inline"
            >
                <el-form-item>
                    <el-input
                        v-model="search.kname"
                        @change="Search"
                        placeholder="模糊搜索名称"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        v-model="search.abbr"
                        @change="Search"
                        placeholder="国家简码"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div>
                <el-button type="primary" size="small" @click="addcountry"
                    >增加</el-button
                >
            </div>
        </div>
        <!-- 列表 -->
        <el-table border v-bind:data="list">
            <!-- <el-table-column label="序号" type="index" width="50"></el-table-column> -->
            <el-table-column label="国家码">
                <template v-slot="scope">
                    <span>{{ scope.row.code }}</span>
                </template>
            </el-table-column>
            <el-table-column label="中文名称">
                <template v-slot="scope">
                    <span>{{ scope.row.chinese }}</span>
                </template>
            </el-table-column>
            <el-table-column label="英文名称">
                <template v-slot="scope">
                    <span>{{ scope.row.english }}</span>
                </template>
            </el-table-column>
            <el-table-column label="区域">
                <template v-slot="scope">
                    <span>{{ scope.row.region }}</span>
                </template>
            </el-table-column>
            <el-table-column label="货币">
                <template v-slot="scope">
                    <span>{{ scope.row.currency }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template v-slot="scope">
                    <el-button
                        type="primary"
                        size="small"
                        @click="editcountry(scope.row)"
                        >修改</el-button
                    >
                    <el-button
                        type="danger"
                        size="small"
                        @click="deletecontry(scope.row.id)"
                        >删除</el-button
                    >
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            @current-change="handleCurrentChange"
            v-model:currentPage="search.page"
            :page-size="search.limit"
            layout="total, prev, pager, next"
            :total="total"
        >
        </el-pagination>

        <!-- 新增修改 -->
        <el-dialog
            :title="title + '国家信息'"
            v-model="dialogFormVisible"
            width="600px"
        >
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item label="国家码" prop="code" label-width="100px">
                    <el-input v-model="form.code" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="国家简码" prop="abbr" label-width="100px">
                    <el-input v-model="form.abbr" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="中文名" prop="chinese" label-width="100px">
                    <el-input
                        v-model="form.chinese"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="英文名" prop="english" label-width="100px">
                    <el-input
                        v-model="form.english"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="所属区域"
                    prop="region"
                    label-width="100px"
                >
                    <!-- <el-input v-model="" autocomplete="off"></el-input> -->
                    <el-select v-model="form.region" placeholder="所属区域">
                        <el-option
                            v-for="item in regionlist"
                            v-bind:key="item.code"
                            v-bind:label="item.name"
                            v-bind:value="item.code"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="货币" prop="currency" label-width="100px">
                    <el-input
                        v-model="form.currency"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogFormVisible = false"
                        >取 消</el-button
                    >
                    <el-button
                        type="primary"
                        v-if="!edit"
                        @click="Validate(addconfirm)"
                        >新增</el-button
                    >
                    <el-button
                        type="primary"
                        v-if="edit"
                        @click="Validate(editconfirm)"
                        >修改</el-button
                    >
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6">
import api from '../../../axios/pack'
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      title: '',
      search: {
        page: 1,
        limit: 10,
        kname: '',
        abbr: '',
      },
      list: [],
      total: 0,
      dialogFormVisible: false,
      form: {
        id: 0,
        code: '',
        abbr: '',
        chinese: '',
        english: '',
        region: '',
        currency: ''
      },
      rules: {
        code: [{ required: true, message: '请输入国家码', trigger: 'blur' }],
        abbr: [{ required: true, message: '请输入国家简码', trigger: 'blur' }],
        chinese: [{ required: true, message: '请输入中文名', trigger: 'blur' }],
        english: [{ required: true, message: '请输入英文名', trigger: 'blur' }],
        region: [{ required: true, message: '请输入所属区域', trigger: 'blur' }],
        currency: [{ required: true, message: '请输入所属货币', trigger: 'blur' }],
      },
      edit: false,
      regionlist: []
    }
  },
  computed: {},
  watch: {},
  methods: {
    GetRegion () {
      api.ReginList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.regionlist = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    handleCurrentChange(val) {
      this.search.page = val
      this.Search()
    },
    Search() {
      this.api.CountryList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    addcountry () {
      this.form = {
        id: '',
        element_code: '',
        element_content: '',
        language_code: '',
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.title = '新增'
      this.edit = false
      this.dialogFormVisible = true
    },
    editcountry(item) {
      this.edit = true
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.title = '修改'
      this.api.CountryDetail(item).then(res => {
         if (res.data.code == 200) {
          this.dialogFormVisible = true
          this.form = res.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    deletecontry(item) {
      this.$confirm('是否删除该国家?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.api.CountryDelete({
          id: item
        }).then(res => {
          if (res.data.code == 200) {
            this.Search()
            this.$message.success('删除成功')
            return
          }
          this.$message.error('删除失败'+ res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    addconfirm () {
      this.update()
    },
    update (){
      this.api.CountryUpdate({
        id: +this.form.id,
        code: +this.form.code,
        abbr: this.form.abbr,
        chinese: this.form.chinese,
        english: this.form.english,
        region: this.form.region,
        currency: this.form.currency,
      }).then(res => {
        if (res.data.code == 200) {
          this.Search()
          this.$message.success(this.title + '成功')
          this.dialogFormVisible = false
          return
        }
        this.$message.error(this.title + '失败' + res.data.msg)
      })
    },
    editconfirm () {
      this.update()
    }
  },
  filters: {},
  mounted () {
    this.Search()
  },
  created () {
    this.GetRegion()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus"></style>
